define([], () => {
  const bmiCalculator = () => {
    const component = {};

    const _config = {
      attrib: {
        bmiResultVal: 'data-bmi-result',
        weightScalePointer: 'data-percent',
        defaultUnit: 'data-default-unit'
      },
      selectors:{
        ageInput: '[data-js-element=age-input]',
        ageRangeSlider:'[data-js-element=age-range-slider]',
        metricHeightInput:'[data-js-element=height-cm-input]',
        imperialHeightFeetInput:'[data-js-element=height-ft-input]',
        imperialHeightInchesInput:'[data-js-element=height-in-input]',
        heightRangeSlider:'[data-js-element=height-range-slider]',
        metricWeightInput:'[data-js-element=weight-kg-input]',
        imperialWeightInput:'[data-js-element=weight-lbs-input]',
        imperialWeightStoneInput:'[data-js-element=weight-st-input]',
        imperialWeightPoundsInput:'[data-js-element=weight-lb-input]',
        weightRangeSlider:'[data-js-element=weight-range-slider]',
        unitToggle: '[data-js-element=unit-toggle]',
        imperialFields: '[data-js-element=imperial-element]',
        metricFields: '[data-js-element=metric-element]',
        bmiResultSlider: '[data-bmi-result]',
        bmiValue: '[data-js-element=bmi-value]',
        underWeightBand: '[data-js-element=under-weight-band]',
        normalWeightBand: '[data-js-element=normal-weight-band]',
        overWeightBand: '[data-js-element=over-weight-band]',
        obeseWeightBand: '[data-js-element=obese-weight-band]',
        defaultWeightSummary: '[data-js-element=default-bmi-summary]',
        weightScaleBar: '[data-js-element=bmiCalculator_weightScaleBar]',
        idealWeightText: '[data-js-element=ideal-weight-text]',
        idealMetricWeight: '[data-js-element=ideal-metric-weight]',
        idealImperialWeight: '[data-js-element=ideal-imperial-weight]',
        idealAltImperialWeight: '[data-js-element=ideal-alt-imperial-weight]',
        calculateBtn: '[data-js-element=calculate-btn]',
        formSummary: '[data-js-element=form-summary]'
      },
      classes: {
        hideClass: 'bmiCalculator_hide-element',
        invisibleClass: 'bmiCalculator_invisible-element',
        calculateBtnSubmittedClass: 'bmiCalculator_button-calculate--submitted'
      },
      settings: {
        minAge: 18,
        maxAge: 70,
        startingAge: 18,
        minHeight: 130,
        maxHeight: 200,
        startingHeight: 130,
        minWeight: 30,
        maxWeight: 150,
        startingWeight: 30,
        toggleState: false,
        initialBmiCalculated: false
      }
    };

    const _ageInputObservers = [];
    const _heightInputObservers = [];
    const _weightInputObservers = [];
    const _toggleObservers = [];

    const _init = (element) => {
      component.element = element;

      component.elements = {
        ageInput: element.querySelector(component.config.selectors.ageInput),
        ageRangeSlider: element.querySelector(component.config.selectors.ageRangeSlider),
        metricHeightInput: element.querySelector(component.config.selectors.metricHeightInput),
        imperialHeightFeetInput: element.querySelector(component.config.selectors.imperialHeightFeetInput),
        imperialHeightInchesInput: element.querySelector(component.config.selectors.imperialHeightInchesInput),
        heightRangeSlider: element.querySelector(component.config.selectors.heightRangeSlider),
        metricWeightInput: element.querySelector(component.config.selectors.metricWeightInput),
        imperialWeightInput: element.querySelector(component.config.selectors.imperialWeightInput),
        imperialWeightStoneInput: element.querySelector(component.config.selectors.imperialWeightStoneInput),
        imperialWeightPoundsInput: element.querySelector(component.config.selectors.imperialWeightPoundsInput),
        weightRangeSlider: element.querySelector(component.config.selectors.weightRangeSlider),
        unitToggle: element.querySelector(component.config.selectors.unitToggle),
        metricElements: [...component.element.querySelectorAll(component.config.selectors.metricFields)],
        imperialElements: [...component.element.querySelectorAll(component.config.selectors.imperialFields)],
        bmiResultSlider: component.element.querySelector(component.config.selectors.bmiResultSlider),
        bmiValue: element.querySelector(component.config.selectors.bmiValue),
        underWeightBand: [...component.element.querySelectorAll(component.config.selectors.underWeightBand)],
        normalWeightBand: [...component.element.querySelectorAll(component.config.selectors.normalWeightBand)],
        overWeightBand: [...component.element.querySelectorAll(component.config.selectors.overWeightBand)],
        obeseWeightBand: [...component.element.querySelectorAll(component.config.selectors.obeseWeightBand)],
        defaultWeightSummary: [...component.element.querySelectorAll(component.config.selectors.defaultWeightSummary)],
        weightScaleBar: component.element.querySelector(component.config.selectors.weightScaleBar),
        idealWeightText: component.element.querySelector(component.config.selectors.idealWeightText),
        idealMetricWeight: component.element.querySelector(component.config.selectors.idealMetricWeight),
        idealImperialWeight: component.element.querySelector(component.config.selectors.idealImperialWeight),
        idealAltImperialWeight: component.element.querySelector(component.config.selectors.idealAltImperialWeight),
        calculateBtn: element.querySelector(component.config.selectors.calculateBtn),
        formSummary: element.querySelector(component.config.selectors.formSummary)
      };

      component.config.settings.toggleState = component.elements.unitToggle.getAttribute(component.config.attrib.defaultUnit) === 'true';

      component.subscribe(component.updateAge, component.ageInputObservers);
      component.subscribe(component.updateMetricHeight, component.heightInputObservers);
      component.subscribe(component.updateImperialHeight, component.heightInputObservers);
      component.subscribe(component.updateMetricWeight, component.weightInputObservers);
      component.subscribe(component.updateImperialWeight, component.weightInputObservers);
      component.subscribe(component.updateAltImperialWeight, component.weightInputObservers);
      component.subscribe(component.handleToggle, component.toggleObservers);

      component.ageListener();
      component.heightListener();
      component.weightListener();
      component.rangeSliderListener();
      component.listenForToggle();
      component.observeBmiResult();
      component.triggerFirstCalculation();

      component.notify(component.config.settings.startingAge, component.ageInputObservers);
      component.notify(component.config.settings.startingHeight, component.heightInputObservers);
      component.notify(component.config.settings.startingWeight, component.weightInputObservers);
      component.notify(component.config.settings.toggleState, component.toggleObservers);

      return component;
    };

    const _subscribe = (action, observers) => {
      observers.push(action);
    };

    const _notify = (data, observers) => {
      observers.forEach(observer => observer(data));
    };

    const _updateAge = (data) => {
      let value = component.validateInput(data, component.config.settings.minAge, component.config.settings.maxAge);
      component.elements.ageInput.value = value;
      component.elements.ageRangeSlider.value = value;
      component.setSliderProgress(component.elements.ageRangeSlider, component.config.settings.minAge, component.config.settings.maxAge);
    };

    const _updateMetricHeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minHeight, component.config.settings.maxHeight);
      component.elements.metricHeightInput.value = value;
      component.elements.heightRangeSlider.value = value;
      component.calculateBmi(component.elements.metricWeightInput.value, component.elements.metricHeightInput.value);
      component.calculateIdealWeight(component.elements.metricHeightInput.value);
      component.setSliderProgress(component.elements.heightRangeSlider, component.config.settings.minHeight, component.config.settings.maxHeight);
    };

    const _updateImperialHeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minHeight, component.config.settings.maxHeight);
      const {feet, inches} = component.convertHeightToImperial(value);
      component.elements.imperialHeightFeetInput.value = feet;
      component.elements.imperialHeightInchesInput.value = inches;
      component.elements.heightRangeSlider.value = value;
    };

    const _updateMetricWeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minWeight, component.config.settings.maxWeight);
      component.elements.metricWeightInput.value = value;
      component.elements.weightRangeSlider.value = value;
      component.calculateBmi(component.elements.metricWeightInput.value, component.elements.metricHeightInput.value);
      component.setSliderProgress(component.elements.weightRangeSlider, component.config.settings.minWeight, component.config.settings.maxWeight);
    };

    const _updateImperialWeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minWeight, component.config.settings.maxWeight);
      component.elements.imperialWeightInput.value = component.convertWeightToImperial(value);
      component.elements.weightRangeSlider.value = value;
    };

    const _updateAltImperialWeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minWeight, component.config.settings.maxWeight);
      const {stones, pounds} = component.convertWeightToAltImperial(value);
      component.elements.imperialWeightStoneInput.value = stones;
      component.elements.imperialWeightPoundsInput.value = pounds;
      component.elements.weightRangeSlider.value = value;
    };

    const _handleToggle = (data) => {
      component.elements.metricElements.forEach((element) => {
        element.classList.add(component.config.classes.hideClass);
      });
      component.elements.imperialElements.forEach((element) => {
        element.classList.add(component.config.classes.hideClass);
      });
      if (data === true){
        component.elements.imperialElements.forEach((element) => {
          element.classList.remove(component.config.classes.hideClass);
        });
      } else {
        component.elements.metricElements.forEach((element) => {
          element.classList.remove(component.config.classes.hideClass);
        });
      }
      component.elements.unitToggle.checked = component.config.settings.toggleState;
    };

    const _ageListener = () => {
      component.elements.ageInput.addEventListener("change" , (e)=> {
        component.notify(e.target.value, component.ageInputObservers);
      });
    };

    const _heightListener = () => {
      component.elements.metricHeightInput.addEventListener("change" , (e)=> {
        component.notify(e.target.value, component.heightInputObservers);
      });
      component.elements.imperialHeightFeetInput.addEventListener("change" , (e)=> {
        let metricHeight = component.convertHeightToMetric(e.target.value, component.elements.imperialHeightInchesInput.value);
        component.notify(metricHeight, component.heightInputObservers);
      });
      component.elements.imperialHeightInchesInput.addEventListener("change" , (e)=> {
        let metricHeight = component.convertHeightToMetric(component.elements.imperialHeightFeetInput.value, e.target.value);
        component.notify(metricHeight, component.heightInputObservers);
      });
    };

    const _weightListener = () => {
      component.elements.metricWeightInput.addEventListener("change" , (e)=> {
        component.notify(e.target.value, component.weightInputObservers);
      });
      component.elements.imperialWeightInput.addEventListener("change" , (e)=> {
        let metricWeight = component.convertWeightToMetric(e.target.value);
        component.notify(metricWeight, component.weightInputObservers);
      });
      component.elements.imperialWeightStoneInput.addEventListener("change" , (e)=> {
        let metricWeight = component.convertAltImperialWeightToMetric(e.target.value, component.elements.imperialWeightPoundsInput.value);
        component.notify(metricWeight, component.weightInputObservers);
      });
      component.elements.imperialWeightPoundsInput.addEventListener("change" , (e)=> {
        let metricWeight = component.convertAltImperialWeightToMetric(component.elements.imperialWeightStoneInput.value, e.target.value);
        component.notify(metricWeight, component.weightInputObservers);
      });
    };

    const _rangeSliderListener = () => {
      let eventType = window.document.documentMode ? "change" : "input";
      component.elements.weightRangeSlider.addEventListener(eventType , (e)=> {
        component.notify(e.target.value, component.weightInputObservers);
      });
      component.elements.heightRangeSlider.addEventListener(eventType , (e)=> {
        component.notify(e.target.value, component.heightInputObservers);
      });
      component.elements.ageRangeSlider.addEventListener(eventType , (e)=> {
        component.notify(e.target.value, component.ageInputObservers);
      });
    };

    const _listenForToggle = () => {
      component.elements.unitToggle.addEventListener("click", () => {
        component.config.settings.toggleState = !component.config.settings.toggleState;
        component.notify(component.config.settings.toggleState, component.toggleObservers);
      });
    };

    const _setSliderProgress = (target, min, max) => {
      let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

      if (isChrome || isSafari) {
        target.style.setProperty('background-size', ((target.value - min) * 100 / (max - min)) + "% 100%");
      }
    };

    const _validateInput = (value, min, max) => {
      let result;
      if (value < min) {
        result = min;
      } else if (value > max) {
        result = max;
      } else {
        result = value;
      }
      return result;
    };

    const _convertHeightToImperial = (value) => {
      let totalInches = Math.round(Number(value)/2.54);
      let inches = totalInches%12;
      let feet = (totalInches-inches)/12;
      return {feet, inches};
    };

    const _convertHeightToMetric = (feet, inches) => {
      let exactFeet = (Number(feet) * 12) + Number(inches);
      return Math.round(exactFeet * 2.54);
    };

    const _convertWeightToMetric = (value) => {
      return Math.round(value * 0.453);
    };

    const _convertWeightToImperial = (value) => {
      return Math.round(value * 2.2);
    };

    const _convertWeightToAltImperial = (value) => {
      let actualStones = value/6.35;
      let stones = Math.floor(actualStones);
      let pounds = Math.floor((actualStones - stones) * 14);
      return {stones, pounds};
    };

    const _convertAltImperialWeightToMetric = (stones, pounds) => {
      let actualStones = Number(stones) + (Number(pounds)/14);
      return Math.round(actualStones*6.35);
    };

    const _observeBmiResult = () => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          let bmiResult = mutation.target.getAttribute(component.config.attrib.bmiResultVal);
          component.calculateWeightScalePointer(bmiResult);
        });
      });
      observer.observe(component.elements.bmiResultSlider, {
        attributes: true,
        attributeFilter: [component.config.attrib.bmiResultVal],
      });
    };

    const _calculateWeightScalePointer = (val) => {
      let minVal, maxVal, pointer;
      if (val >= 7.5 && val <= 18.5) {
        minVal = 7.5;
        maxVal = 18.5;
        pointer = 0;
        component.showResultDescription(component.elements.underWeightBand);
      } else if (val > 18.5 && val <= 25) {
        minVal = 18.5;
        maxVal = 25;
        pointer = 25;
        component.showResultDescription(component.elements.normalWeightBand);
      } else if (val > 25 && val <= 30) {
        minVal = 25;
        maxVal = 30;
        pointer = 50;
        component.showResultDescription(component.elements.overWeightBand);
      } else if (val > 30) {
        minVal = 30;
        maxVal = 35;
        pointer = 75;
        component.showResultDescription(component.elements.obeseWeightBand);
      }

      const weightRangePercent = (val - minVal) * 100 / (maxVal - minVal);
      const weightScalePercent = val < 7.5 ? 0 : val > 35 ? 100 : pointer + weightRangePercent / 4;
      component.elements.weightScaleBar.setAttribute(component.config.attrib.weightScalePointer, Math.round(weightScalePercent));
    };

    const _calculateBmi = (weight, height) => {
      let bmi = Math.round((weight / (Math.pow((height / 100), 2))) * 10) / 10;
      if(component.config.settings.initialBmiCalculated === true){
        component.elements.bmiValue.innerHTML = bmi;
        component.elements.bmiResultSlider.setAttribute(component.config.attrib.bmiResultVal, bmi);
      }
    };

    const _calculateIdealWeight = (height) => {
      let minIdealWeight = Math.round(18.5 * (Math.pow((height/100), 2)));
      let maxIdealWeight = Math.round(25 * (Math.pow((height/100), 2)));
      let minStones = component.convertWeightToAltImperial(minIdealWeight).stones;
      let minPounds = component.convertWeightToAltImperial(minIdealWeight).pounds;
      let maxStones = component.convertWeightToAltImperial(maxIdealWeight).stones;
      let maxPounds = component.convertWeightToAltImperial(maxIdealWeight).pounds;

      component.elements.idealMetricWeight.innerHTML = minIdealWeight + " kg - " + maxIdealWeight + " kg";
      component.elements.idealImperialWeight.innerHTML = component.convertWeightToImperial(minIdealWeight) + " lbs - " + component.convertWeightToImperial(maxIdealWeight) + " lbs";
      component.elements.idealAltImperialWeight.innerHTML = minStones + " st " + minPounds + " lb - " + maxStones + " st " + maxPounds + " lb";
    };

    const _showResultDescription = (selectedBand) => {
      let bandArr = [component.elements.underWeightBand, component.elements.normalWeightBand, component.elements.overWeightBand, component.elements.obeseWeightBand];
      bandArr.forEach((band) => {
        if (band === selectedBand) {
          band.forEach((bandElement) => {bandElement.classList.remove(component.config.classes.hideClass)});
        } else {
          band.forEach((bandElement) => {bandElement.classList.add(component.config.classes.hideClass)});
        }
      });
    };

    const _triggerFirstCalculation = () => {
      component.elements.calculateBtn.addEventListener("click", () => {
        component.config.settings.initialBmiCalculated = true;
        component.calculateBmi(component.elements.metricWeightInput.value, component.elements.metricHeightInput.value);
        component.calculateIdealWeight(component.elements.metricHeightInput.value);
        component.elements.weightScaleBar.classList.remove(component.config.classes.invisibleClass);
        component.elements.idealWeightText.classList.remove(component.config.classes.invisibleClass);
        component.elements.defaultWeightSummary.forEach(element => element.classList.add(component.config.classes.hideClass));
        if (window.matchMedia("(max-width: 900px)").matches) {
          component.scrollTo(document.documentElement, (component.elements.formSummary.offsetTop-50), 600);
        }
        component.elements.calculateBtn.classList.add(component.config.classes.calculateBtnSubmittedClass);
      });
    };

    const _scrollTo = (element, to, duration) => {
      if (duration <= 0) return;
      let difference = to - element.scrollTop;
      let perTick = difference / duration * 10;

      setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          component.scrollTo(element, to, duration - 10);
      }, 10);
    };

    component.config = _config;
    component.init = _init;
    component.subscribe = _subscribe;
    component.notify = _notify;
    component.ageInputObservers =_ageInputObservers;
    component.ageListener = _ageListener;
    component.updateAge = _updateAge;
    component.heightInputObservers = _heightInputObservers;
    component.updateMetricHeight = _updateMetricHeight;
    component.updateImperialHeight = _updateImperialHeight;
    component.heightListener = _heightListener;
    component.weightInputObservers = _weightInputObservers;
    component.updateMetricWeight = _updateMetricWeight;
    component.updateImperialWeight = _updateImperialWeight;
    component.updateAltImperialWeight = _updateAltImperialWeight;
    component.weightListener = _weightListener;
    component.rangeSliderListener = _rangeSliderListener;
    component.validateInput = _validateInput;
    component.convertHeightToImperial = _convertHeightToImperial;
    component.convertHeightToMetric = _convertHeightToMetric;
    component.convertWeightToImperial = _convertWeightToImperial;
    component.convertWeightToMetric = _convertWeightToMetric;
    component.convertWeightToAltImperial = _convertWeightToAltImperial;
    component.convertAltImperialWeightToMetric = _convertAltImperialWeightToMetric;
    component.listenForToggle = _listenForToggle;
    component.observeBmiResult = _observeBmiResult;
    component.calculateWeightScalePointer = _calculateWeightScalePointer;
    component.calculateBmi = _calculateBmi;
    component.calculateIdealWeight = _calculateIdealWeight;
    component.showResultDescription = _showResultDescription;
    component.triggerFirstCalculation = _triggerFirstCalculation;
    component.setSliderProgress = _setSliderProgress;
    component.scrollTo = _scrollTo;
    component.handleToggle = _handleToggle;
    component.toggleObservers = _toggleObservers;

    return component;
  };

  return bmiCalculator;
});
